import React from 'react';
import "./AboutData.css";

const AboutData = () => {
  return (
    <div>
    <div className='AboutData'>
      <h2>Our Vision</h2>
      <p>"Our vision at Augere Technologies is to revolutionize the brand building landscape, empowering businesses of all sizes to thrive in a rapidly evolving digital world. We aspire to be the catalyst for innovation and success, driving meaningful growth for our clients through cutting-edge solutions and unwavering dedication."</p>
      <h3>Our Mission : <span>Innovate. Empower. Succeed.</span></h3>
      <h1>Foundation</h1>
      <p>At Augere Technologies, our foundation is rooted in a commitment to innovation, integrity, and excellence. <br/><br/>

      Innovation is at the core of everything we do; we constantly seek out new ideas, technologies, and strategies to drive meaningful progress and deliver exceptional results for our clients.<br/><br/>

      Integrity guides our actions and decisions, ensuring that we always operate with honesty, transparency, and respect for our clients, partners, and colleagues. We uphold the highest standards of professionalism and ethics in all aspects of our work, earning the trust and confidence of those we serve. <br/><br/>

      Excellence is our ultimate goal; we strive for excellence in every project, endeavoring to surpass expectations and set new standards of quality and performance. <br/><br/>

      By staying true to these principles, we are able to build strong foundations for success, both for our clients and for our own company. <br/><br/>

      At Augere Technologies, we are dedicated to creating lasting value, making a positive impact, and empowering businesses to thrive in a dynamic and ever-changing world.</p>
      
  
    </div>
    </div>
  )
}

export default AboutData
